@charset "utf-8";
$right-sidebar-width-narrow: 300px;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
$sans-serif : "Inter", sans-serif;
$type-size-1 : 56px;
$type-size-2 : 40px;
$type-size-3 : 24px;
$type-size-4 : 20px;
$type-size-5 : 16px;
$type-size-6 : 14px;
$type-size-7 : 12px;
$type-size-8 : 10px;

$text-color: #2E2836;
$muted-text-color: #878FB1;
$border-color: #E7ECF5;
$link-color: #039be5;
$link-color-visited: $link-color;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.page__meta {
  margin: 0;

  .btn--linkedin {
    color: #fff;
  }
}

.page__meta-sep::before {
    padding-left: 0;
    padding-right: 0;
}

.page__taxonomy-item {
  margin-right: 4px;
  margin-bottom: 0;
  padding: 2px 4px; 
}

.list__item {
  margin-bottom: 32px;
}

.sidebar {
  opacity: 1;
}

.author__bio {
  margin: 0;

  p {
    margin: 0;
    margin-bottom: 16px;
  }
}

.author__urls a {
  font-size: $type-size-6;
}

.btn {
  padding: 4px 8px;
}

.btn--linkedin {
  color: #fff;
}

.page__content {
  margin-top: 1em;

  p {
    font-size: $type-size-5;
    margin: 0 0 1em;
  }

  h2 {
    padding-top: 0.5em;
    border-bottom: none;
  }

  li {
    font-size: $type-size-5;
  }
}

.page__share {
  margin-top: 0.5em;
}

.page__related {
  margin-top: 1em;
}

.page__footer footer {
  text-align: center;
}

a {
  text-decoration: none;
}